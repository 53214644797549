import React from "react";
import theme from "theme";
import { Theme, Text, Box, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				АВТОМОЙКА БыстроЧисто
			</title>
			<meta name={"description"} content={"Необыкновенные впечатления от мойки автомобиля, выходящие за рамки простой чистки."} />
			<meta property={"og:title"} content={"АВТОМОЙКА БыстроЧисто"} />
			<meta property={"og:description"} content={"Необыкновенные впечатления от мойки автомобиля, выходящие за рамки простой чистки."} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1607860108855-64acf2078ed9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1607860108855-64acf2078ed9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1607860108855-64acf2078ed9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1607860108855-64acf2078ed9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1607860108855-64acf2078ed9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1607860108855-64acf2078ed9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1607860108855-64acf2078ed9?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="linkBox" />
			<Override slot="box4" />
		</Components.Header>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-8">
			<Override slot="SectionContent" md-margin="0px 35px 0px 35px" sm-margin="0px 15px 0px 15px" />
			<Box
				min-width="10px"
				min-height="10px"
				display="flex"
				justify-content="space-between"
				align-items="center"
				margin="0px 0px 0 0px"
				lg-flex-direction="column"
			>
				<Text
					margin="0px 0px 15px 0px"
					font="--headline2"
					color="--darkL1"
					lg-margin="0px 0px 30px 0px"
					lg-text-align="center"
					sm-font="normal 600 28px/1.2 --fontFamily-sans"
					sm-text-align="left"
					sm-width="100%"
				>
					Наше комплексное меню услуг
				</Text>
			</Box>
			<Box
				min-width="10px"
				min-height="10px"
				display="flex"
				justify-content="space-between"
				align-items="center"
				margin="0px 0px 50px 0px"
				lg-flex-direction="column"
				font="--base"
			>
				<Text
					margin="0px 0px 15px 0px"
					font="--base"
					color="--darkL1"
					lg-margin="0px 0px 30px 0px"
					lg-text-align="center"
					sm-font="normal 600 28px/1.2 --fontFamily-sans"
					sm-text-align="left"
					sm-width="100%"
				>
					В автомойке БыстроЧисто мы рады предложить широкий спектр услуг, призванных удовлетворить все ваши потребности в уходе за автомобилем. Наша цель — от базовой мойки до комплексных пакетов детализации — сохранить ваш автомобиль в наилучшем виде. Наше современное оборудование и опытная команда всегда гарантируют первоклассные результаты.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-6.jpg?v=2024-06-07T06:52:09.831Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Базовые пакеты стирки
						</Text>
						<Text
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#bec4ca"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="left"
						>
							Наши базовые наборы для мытья идеально подходят для тех, кому нужна быстрая и эффективная очистка.{"\n"}
							<br />
							Они включают в себя: Наружная мойка: тщательная внешняя очистка с использованием высококачественных средств для удаления грязи и копоти.
Пылесосить салон: быстрая уборка салона, чтобы поддерживать порядок в салоне вашего автомобиля.{"\n"}
							<br />
							Очистка шин: Тщательная очистка шин от тормозной пыли и дорожной грязи.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-3.jpg?v=2024-06-07T06:52:09.824Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Пакеты стирки Делюкс
						</Text>
						<Text
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#bec4ca"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="left"
						>
							Наши пакеты Делюкс предлагают более комплексную уборку.{"\n"}
							<br />
							Они включают в себя: Ручная стирка: бережная ручная стирка для тщательной и бережной очистки.{"\n"}
							<br />
							Нанесение на стекло: Защитная полироль, наносимая для сохранения блеска вашего автомобиля и защиты его краски.{"\n"}
							<br />
							Очистка приборной панели и консоли: Тщательная очистка приборной панели и консоли для безупречного салона.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-4.jpg?v=2024-06-07T06:52:09.815Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Детейлинговые услуги
						</Text>
						<Text
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#bec4ca"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="left"
						>
							Наши услуги по детейлингу идеально подходят для тех, кому нужна тщательная и детальная уборка.{" "}
							<br />
							Они включают:
Коррекция краски: профессиональная коррекция краски, которая восстанавливает первоначальный блеск вашего автомобиля, удаляя царапины и завитки.{"\n"}
							<br />
							Детейлинг салона: Глубокая чистка салона, включая сидения, ковры и обивку.{"\n"}
							<br />
							Очистка двигателя. Тщательная очистка двигателя от масла и грязи сохранит внешний вид вашего двигателя во время его работы.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-2.jpg?v=2024-06-07T06:52:09.814Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Специальные услуги
						</Text>
						<Text
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#bec4ca"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="left"
						>
							Наши специализированные услуги, предназначенные для удовлетворения конкретных потребностей, предлагают целенаправленный уход.{"\n"}
							<br />
							Они включают в себя: Восстановление фар: восстановление размытых и выцветших фар для улучшения видимости и яркого внешнего вида.{"\n"}
							<br />
							Керамическое покрытие: усовершенствованное керамическое покрытие для длительной защиты и улучшения блеска.{"\n"}
							<br />
							Устранение запаха: эффективные средства по удалению запаха, которые сохранят свежий запах вашего автомобиля.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/1-1.jpg?v=2024-06-07T06:52:09.805Z) center/cover repeat scroll padding-box"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							Постоянным клиентам
						</Text>
						<Text
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#bec4ca"
							margin="16px 25px 16px 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
							text-align="left"
						>
							Наши планы членства предлагают отличное соотношение цены и качества для регулярного технического обслуживания автомобиля.{"\n"}
							<br />
							Они включают в себя: Неограниченное количество стирок: наслаждайтесь неограниченным количеством стирок тонального крема за установленную ежемесячную плату.{"\n"}
							<br />
							Детализация со скидкой: получите скидки на услуги детализации, став участником. Приоритетное обслуживание: участники могут воспользоваться приоритетным обслуживанием, чтобы сэкономить время.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="30px 30px 30px 30px"
					background="linear-gradient(180deg,rgba(7, 11, 57, 0.15) 0%,rgba(5, 4, 49, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6661a2a3fdcae00021e2f721/images/2-1.jpg?v=2024-06-07T06:52:09.809Z) center/cover repeat scroll padding-box"
					height="480px"
					align-self="center"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 600 20px/1.2 --fontFamily-sans" color="--light" text-align="center">
							В автомойке БыстроЧисто мы верим в нечто большее, чем просто чистый автомобиль. Мы стремимся предоставить опыт, который оставит вас полностью удовлетворенным. Широкий спектр наших услуг гарантирует, что вашему автомобилю будет предоставлен наилучший уход.
						</Text>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-5" lg-padding="2rem 0 1rem 0">
			<Override
				slot="SectionContent"
				padding="0px 0 0px 0px"
				lg-padding="0px 30% 0px 0px"
				md-padding="0px 0 0px 0px"
				sm-min-width="280px"
			/>
			<Text
				margin="0px 0 40px 0px"
				font="normal 600 72px/1.2 --fontFamily-sans"
				color="#090e28"
				letter-spacing="4px"
				md-font="normal 600 56px/1.2 --fontFamily-sans"
				sm-font="normal 600 42px/1.2 --fontFamily-sans"
				width="100%"
			>
				Для получения более подробной информации свяжитесь с нами
+77019338382
			</Text>
			<Text margin="0px 0px 50px 0px" font="--lead" color="#334455" sm-margin="0px 0px 25px 0px">
				И это только начало. Узнайте все предложения, связавшись с нами. Ваш автомобиль заслуживает самого лучшего, и мы здесь, чтобы обеспечить именно это! Хотите узнать больше о наших услугах или готовы записаться на прием? Свяжитесь с нами сегодня или посетите наше представительство, чтобы лично ощутить разницу БыстроЧисто. Наш дружелюбный персонал всегда готов помочь вам с любыми вопросами или особыми запросами.
			</Text>
		</Section>
		<Components.Header>
			<Override slot="link3">
				+77019338382
			</Override>
		</Components.Header>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb673d867c86001f1b379d"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});